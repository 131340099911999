import React from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.scss";
function Navbar({ userName, bg }) {
  return (
    <div className={bg ? `navbar-div${bg}` : "navbar-div"}>
      <div>
        <img
          className="img-logo"
          src="/assets/img/logo-navbar.png"
          alt="logo"
        />
      </div>
      <div>
        {userName ? (
          <div className="user-info-div">
            <div className="user-info">
              <p className="text-body-2">Hi! {userName}</p> 
              <Link to={"/login"}>Log out</Link>
            </div>
            <img src="/assets/img/icon-login.png" alt="login" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;

import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { useForm } from "react-hook-form";
import { getUserInfoDB, singIn } from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loadingSpinner";

function Login() {
  let navigate = useNavigate();
  const { register, required, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setuser] = useState();
  const [userName, setUserName] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noUserPermission, setNoUserPermission] = useState();

  useEffect(() => {
    if (user) {
      getUserInfo(user.uid);
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (user && userName) {
      goToHome();
    } else {
      setIsLoading();
    }
  }, [userName]); // eslint-disable-line

  const onSubmit = async () => {
    resetState();
    setIsLoading(true);
    const userLogged = await singIn(email, password);
    if (
      userLogged &&
      (userLogged.uid === "W8jMIQVVJabGUZFkcSGeXWhMh4h2" ||
        userLogged.uid === "ENTVsJ4AHtRcDML2mAaoAcHT9bP2")
    ) {
      setuser(userLogged);
    } else if (
      userLogged &&
      (userLogged.uid !== "W8jMIQVVJabGUZFkcSGeXWhMh4h2" ||
        userLogged.uid !== "ENTVsJ4AHtRcDML2mAaoAcHT9bP2")
    ) {
      setNoUserPermission(true);
      setIsLoading();
    } else {
      setError(true);
      setIsLoading();
    }
  };

  const resetState = () => {
    setuser();
    setError(false);
  };

  const getUserInfo = async (userId) => {
    const data = await getUserInfoDB(userId);
    setUserName(data.name);
  };

  const goToHome = () => {
    navigate("/home", {
      state: {
        user: {
          userId: user.uid,
          userEmail: user.email,
          userName: userName,
        },
      },
    });
  };

  console.log(noUserPermission);

  return (
    <div className="login-div">
      <Navbar />
      <div className="form-div">
        <div>
          <h2 className="form-title">SIGN IN</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <p className="text-overline form-label">Email</p>
              <div>
                <input
                  {...register("email")}
                  id="email"
                  className="text-body-1 form-inputs"
                  type="text"
                  value={email}
                  placeholder="Your Email"
                  onChange={(event) => {
                    resetState();
                    setEmail(event.target.value);
                    setNoUserPermission();
                  }}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-done.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <p className="text-overline form-label">Password</p>
              <div>
                <input
                  {...register("password", { required })}
                  id="password"
                  className="text-body-1 form-inputs"
                  type="password"
                  value={password}
                  autoComplete="off"
                  placeholder="Your Password"
                  onChange={(event) => {
                    resetState();
                    setPassword(event.target.value);
                    setNoUserPermission();
                  }}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-done.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <div className="form-submit-div">
                <div>
                  {" "}
                  {isLoading ? (
                    <div style={{ height: "48px" }}>
                      <LoadingSpinner />
                    </div>
                  ) : null}
                  {noUserPermission && (
                    <p
                      style={{
                        color: "#f7a2c1",
                        fontWeight: "bolder",
                        height: "48px",
                      }}
                    >
                      You do not have permissions to access the web
                    </p>
                  )}
                </div>

                {!isLoading && !noUserPermission && (
                  <button
                    id="btn-submit"
                    type="submit"
                    className="btn-accent form-submit-btn"
                  >
                    Sing In
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

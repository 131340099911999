import firebaseConfig from "./firebase";
import "firebase/compat/database";
import { initializeApp } from "@firebase/app";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";

initializeApp(firebaseConfig);

const dbFirestore = getFirestore();
const auth = getAuth();

export const singIn = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/${userId}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { name: "", company: "" };
  }
};

export const getCompaniesInfoDB = async () => {
  const collectionRef = collection(dbFirestore, `companies`);
  const docSnap = await getDocs(collectionRef);
  let allCompanies = [];
  docSnap.forEach((doc) => {
    if (doc.data().id !== "company1" && doc.data().id !== "company2") {
      allCompanies.push(doc.data());
    }
  });
  return allCompanies;
};

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/app.scss";
import Login from "./components/login";
import Home from "./components/home";

function App() {
  return (
    <div className="main-div">
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

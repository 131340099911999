import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/login.scss";
import "../styles/home.scss";
import Navbar from "./navbar";
import { getCompaniesInfoDB } from "../lib/db";
import LoadingSpinner from "./loadingSpinner";

function Home() {
  const [user, setuser] = useState();
  const [companiesInfo, setCompaniesInfo] = useState();

  const location = useLocation();

  useEffect(() => {
    if (location.state.user) {
      setuser(location.state.user);
      sessionStorage.removeItem("id_IRT");
      sessionStorage.removeItem("email_IRT");
      sessionStorage.removeItem("isLoading_IRT");
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      getCompaniesInfo();
    }
  }, [user]);

  const getCompaniesInfo = async () => {
    setCompaniesInfo(await getCompaniesInfoDB());
  };

  return (
    <div className="home-div">
      {user && user.userName ? (
        <Navbar userName={user.userName} />
      ) : (
        <Navbar userName={"User"} />
      )}

      <div className="form-div">
        <img src="/assets/img/home.png" alt="home" />
        <div className="companies-div">
          {companiesInfo && (
            <div className="company-div-title">
              <p className="company-name-title"></p>
              <p className="company-title">Users Started</p>
              <p className="company-title">Users Done</p>
              <p className="company-title">Total Users</p>
            </div>
          )}
          {companiesInfo?.map((company, key) => (
            <div className="company-div" key={key}>
              <p className="company-name">{company.name}</p>
              <p className="company-users">
                {company.usersStarted ? company.usersStarted : 0}
              </p>
              <p className="company-users">
                {company.usersDone ? company.usersDone : 0}
              </p>
              <p className="company-users">
                {company.totalUsers ? company.totalUsers : 0}
              </p>
            </div>
          ))}
          {!companiesInfo && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default Home;
